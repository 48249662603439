import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { submitUserUpdate, getActiveUser } from "../../../Redux/ActionCreator";
import { toast } from "react-toastify";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const EditProfile = ({ open, handleClose, user, triggerRefresh }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [autoGeneratedPassword, setAutoGeneratedPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [userData, setUserData] = useState({
    username: "",
    forename: "",
    surname: "",
    email: "",
    password: "",
    confirm: "",
  });

  // Effect to pre-populate form when editing
  useEffect(() => {
    if (user) {
      setUserData({
        ...userData,
        id: user.id,
        username: user.username,
        forename: user.forename,
        surname: user.surname,
        email: user.email,
        password: "",
        confirm: "",
      });
    }
  }, [user]);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$%&";
    let retVal = "";
    let conditionsMet = 0;
    while (conditionsMet < 4) {
      retVal = "";
      conditionsMet = 0;
      for (let i = 0; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      if (/[a-zA-Z]+/.test(retVal)) conditionsMet++;
      if (/[0-9]+/.test(retVal)) conditionsMet++;
      if (/[\W]+/.test(retVal)) conditionsMet++;
      if (/^[\S]+$/.test(retVal)) conditionsMet++;
    }
    setUserData({ ...userData, password: retVal, confirm: retVal });
    setAutoGeneratedPassword(retVal);
    if (validatePassword(retVal)) {
      setPasswordError("");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(autoGeneratedPassword).then(
      () => toast.success("Password copied to clipboard!"),
      (err) => console.error("Failed to copy: ", err)
    );
  };

  const validatePassword = (password) => {
    const errors = [];
    if (!password.match(/[\S]{8,20}/))
      errors.push("8 to 20 characters required.");
    if (!password.match(/[a-zA-Z]+/))
      errors.push("Include at least one letter.");
    if (!password.match(/[0-9]+/)) errors.push("Include at least one number.");
    if (!password.match(/[\W]+/))
      errors.push("Include at least one special character.");
    if (!password.match(/^[\S]+$/)) errors.push("Spaces not allowed.");
    setPasswordError(errors.join(" "));
    return errors.length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.password && !validatePassword(userData.password)) {
      return;
    }

    if (userData.password && userData.password !== userData.confirm) {
      toast.error("Passwords do not match.");
      return;
    }
    dispatch(submitUserUpdate(userData))
      .then((response) => {
        if (response.success) {
          toast.success("User updated successfully!");
          handleClose();
          dispatch(getActiveUser()); // Optionally re-fetch the active user data if needed
        } else {
          toast.error("Failed to update user.");
        }
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        let fieldErrors = {};
        toast.error("Error updating user: " + error.message);
        if (error.message.includes("Username is already taken")) {
          fieldErrors.username = "Username is already taken";
        }
        if (error.message.includes("invalid email")) {
          fieldErrors.email = "Invalid email";
        }
      });
  };
  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleCancel = () => {
    setPasswordError("");
    setAutoGeneratedPassword("");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit User - {userData.username}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              margin="dense"
              name="forename"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              value={userData.forename}
              onChange={handleChange}
              error={!!validationErrors.forename}
              helperText={validationErrors.forename || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              margin="dense"
              name="surname"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              value={userData.surname}
              onChange={handleChange}
              error={!!validationErrors.surname}
              helperText={validationErrors.surname || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="username"
              label="Username"
              type="text"
              fullWidth
              variant="outlined"
              value={userData.username}
              onChange={handleChange}
              error={!!errorMessages.username}
              helperText={errorMessages.username || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={userData.email}
              onChange={handleChange}
              error={!!errorMessages.email}
              helperText={errorMessages.email || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              value={userData.password}
              onChange={handleChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="confirm"
              label="Confirm Password"
              type={showConfirm ? "text" : "password"}
              fullWidth
              variant="outlined"
              value={userData.confirm}
              onChange={handleChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={() => setShowConfirm(!showConfirm)}
                      edge="end"
                    >
                      {showConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} container spacing={1}>
            <Grid item>
              <Button onClick={generatePassword} color="primary">
                Generate Password
              </Button>
            </Grid>
            {autoGeneratedPassword && (
              <Grid item>
                <IconButton onClick={copyToClipboard} color="primary">
                  <ContentCopyIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Button onClick={generatePassword} color="primary">
              Generate Password
            </Button>
          </Grid>
          {autoGeneratedPassword && (
            <Grid item xs={12} sm={12}>
              <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap>
                <TextField
                  margin="dense"
                  value={autoGeneratedPassword}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
                <IconButton onClick={copyToClipboard} color="primary">
                  <ContentCopyIcon />
                </IconButton>
              </Stack>
            </Grid>
          )} */}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        {/* <Button onClick={handleSubmit} disabled={
            Object.keys(validationErrors).length !== 0 ||
            !userData.username ||
            !userData.forename ||
            !userData.surname ||
            !userData.email ||
            !userData.password ||
            !userData.confirm
          }
          color="primary"
        >
          Update
        </Button> */}
        <Button
          onClick={handleSubmit}
          disabled={
            Object.keys(validationErrors).length !== 0 ||
            !userData.username ||
            !userData.forename ||
            !userData.surname ||
            !userData.email ||
            (userData.password && !userData.confirm)
          }
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfile;
