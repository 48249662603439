import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { toast } from "react-toastify";

const FileDownload = ({ baseUrl }) => {
  const [open, setOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [alert, setAlert] = useState({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert({ open: false, message: "" });
  };

  // const handleDownload = async () => {
  //   // Check that if both dates are provided, date_to is not before date_from
  //   if (dateFrom && dateTo && dayjs(dateFrom).isAfter(dayjs(dateTo))) {
  //     setAlert({ open: true, message: "Invalid date range. 'Date To' cannot be before 'Date From'." });
  //     return;
  //   }

  //   const params = {};
  //   if (dateFrom) params.date_from = dayjs(dateFrom).format("YYYY-MM-DD") + " 00:00:00";
  //   if (dateTo) params.date_to = dayjs(dateTo).format("YYYY-MM-DD") + " 00:00:00";

  //   try {
  //     const response = await axios({
  //       url: `${process.env.REACT_APP_API_BASE_URL}emails/new/export`,
  //       method: "GET",
  //       params: params,
  //       responseType: "blob",
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  //       },
  //     });

  //     const filename = response.headers["content-disposition"]
  //       .split("filename=")[1]
  //       .replace(/"/g, "");
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", filename);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //     handleClose();
  //   } catch (error) {
  //     console.error("Download error:", error);
  //     setAlert({ open: true, message: "Failed to download report. Try again." });
  //   }
  // };

  const handleDownload = async () => {
    // Basic validation to ensure that 'dateFrom' is not after 'dateTo'
    if (dayjs(dateFrom).isAfter(dayjs(dateTo))) {
      toast.error("The start date must not be later than the end date.");
      return;
    }

    setIsLoading(true); // Show loading indicator

    // Constructing the form data
    const formData = new FormData();
    if (dateFrom) {
      formData.append(
        "date_from",
        dayjs(dateFrom).format("YYYY-MM-DD") + " 00:00:00"
      );
    }
    if (dateTo) {
      formData.append(
        "date_to",
        dayjs(dateTo).format("YYYY-MM-DD") + " 00:00:00"
      );
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}emails/new/export`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        responseType: "blob", // Important for downloading files
      });

      const filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .replace(/"/g, "");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      handleClose();
    } catch (error) {
      console.error("Download error:", error);
      // Convert Blob to text to read JSON error
      if (error.response && error.response.data) {
        const reader = new FileReader();
        reader.onload = () => {
          const errorMsg = JSON.parse(reader.result).error.message;
          toast.error(errorMsg);
        };
        reader.onerror = () => {
          toast.error("Failed to read error message.");
        };
        reader.readAsText(error.response.data);
      } else {
        toast.error("Failed to download the report. Unknown error occurred.");
      }
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };


  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadIcon />}
        onClick={handleClickOpen}
        sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
      >
        Generate Report
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: "#444", color: "#ffffff", display: "flex", alignItems: "center" }}>
          Download Report
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4} mt={1}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="date_from"
                label="Date From"
                type="date"
                fullWidth
                variant="standard"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="date_to"
                label="Date To"
                type="date"
                fullWidth
                variant="standard"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: dateFrom || "0001-01-01" // Ensure 'date_to' cannot be before 'date_from' if 'date_from' is set
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDownload}>Download</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ open: false, message: "" })}>
        <Alert onClose={() => setAlert({ open: false, message: "" })} severity="error" sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FileDownload;
